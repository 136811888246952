import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from './loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
    cargando: boolean;
    showProgress: boolean;
    isLoading = false;
    private loadingSubscription: Subscription;

    constructor(private loading: LoadingService) {
        this.cargando = false;
        this.showProgress = true;
    }

    ngOnInit(): void {
        this.loadingSubscription = this.loading.isLoading.subscribe((value) => {
            this.isLoading = value;
        });
    }

    ngOnDestroy() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
    }
}
